import FounderImage from '../../assets/about/founderStory/founder-image.webp';
import './index.css';

export const FounderStory = () => {
  return (
    <div className='blade-pt blade-pb founder-story-bg bg-brownLight'>
      <div className='container'>
        <div className='grid lg:grid-cols-2 items-center justify-center gap-x-20 xl:gap-x-24 2xl:gap-x-36'>
          <div className='flex justify-center lg:justify-end'>
            <img
              className='w-[250px] md:w-[360px] lg:w-[400px] 2xl:w-[450px] h-[250px] md:h-[360px] lg:h-[400px] 2xl:h-[450px] 
              object-cover'
              alt='Narayan Lal Gurjar'
              src={FounderImage}
            />
          </div>

          <div className='flex px-4 flex-col items-center lg:items-start mt-12 lg:mt-0'>
            <p className='uppercase text-green font-plainNormal tracking-wider text-base md:text-lg xl:text-xl'>
              founder’s story
            </p>
            <h2 className='text-greenDark mt-2 xl:mt-4 leading-10 font-plainBold tracking-tight text-center lg:text-left'>
              Inspiration to inception
            </h2>
            <p className='mt-4 xl:mt-7 opacity-[70%] text-[14px] md:text-base font-plainNormal w-full max-w-md md:max-w-[60%] lg:max-w-[86%] text-center lg:text-left'>
              Agriculture is the largest source of livelihood for India, but the harsh realities
              deprive many farmers of a decent living.
            </p>
            <p className='mt-4 font-plainNormal text-[14px] md:text-base w-full max-w-md md:max-w-[60%] lg:max-w-[86%] text-center lg:text-left'>
              One such farmer motivated his child to battle the depriving natural resources while
              protecting the environment. Narayan Lal Gurjar, began his research to find a solution
              that could help save water, rejuvenate the soil, and boost yield. 
            </p>
            <p className='mt-4 font-plainNormal text-[14px] md:text-base w-full max-w-md md:max-w-[60%] lg:max-w-[86%] text-center lg:text-left'>
              The journey led to the discovery of the world's first and only biodegradable
              superabsorbent soil moisturizer; EF Polymer. Going forward, Narayan joined hands with
              other changemakers to bring a sustainable change for the betterment of nature.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
