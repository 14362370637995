import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';

import MixtureAdded from '../../../assets/homepage/creations/mixture-added.webp';
import MixtureDissolve from '../../../assets/homepage/creations/mixture-dissolves.webp';
import OrangePeels from '../../../assets/homepage/creations/orangepeel.webp';
import CombiningWater from '../../../assets/homepage/creations/combining-water.webp';
import PolymerTesting from '../../../assets/homepage/creations/polymer-testing.webp';

// import CircularSliderMobile from "./circularSliderMobile";

export default function CircularSliderMobile() {
  const [currStateInd, setCurrStateInd] = useState(0);

  const stopCarousel = () => {
    return clearInterval();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (currStateInd >= 4) {
        stopCarousel();
        setCurrStateInd(data.length);
      } else {
        setCurrStateInd(currStateInd + 1);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [currStateInd]);

  const data = [
    {
      title: 'Fruit peels',
      description: '100% Biodegradable polymer created out of fruit peels',
      src: OrangePeels,
    },
    {
      title: 'The polymer being tested in the lab',
      description: 'Certified and tested by renowned institutions',
      src: PolymerTesting,
    },
    {
      title: 'Enhancing water absorption',
      description: 'Retains up to 50 times its weight for agricultural use*',
      src: CombiningWater,
    },
    {
      title: 'The mixture is added to the soil',
      description: 'Nourishes the soil with essential micro-nutrients',
      src: MixtureAdded,
    },
    {
      title: 'The mixture dissolves into the ground',
      description: 'Increases the soil’s water retention capacity ',
      src: MixtureDissolve,
    },
  ];

  return (
    <div className='blade-mt-sm '>
      <Swiper
        autoHeight={true}
        cssMode={true}
        navigation={{
          nextEl: '.next-btn',
          prevEl: '.prev-btn',
        }}
        slideToClickedSlide={true}
        allowSlideNext={true}
        modules={[Navigation, Pagination]}
        pagination={true}
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        speed={2000}
        slidesPerView={1}
        className='h-fit overflow-hidden circular-slider-mobile'
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index} className='h-full md:pb-12 pb-8 grid place-content-center '>
              <div className='flex flex-col  h-full max-w-sm md:max-w-md mx-auto'>
                <div className='text-center mx-auto'>
                  <img
                    src={item.src}
                    alt={item.title}
                    className='md:h-[16rem] h-[14rem] object-contain mx-auto'
                  />
                </div>
                <div>
                  <h4 className='text-center font-plainLight pt-2 px-5'>{item.description}</h4>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <span className='swiper-pagination'></span>
      </Swiper>
    </div>
  );
}
