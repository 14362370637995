import React, { useEffect } from 'react';
import { Growth } from './growth';
import { Team } from './team';
import Career from '../../modules/core/career';
import { GuidingValues } from './guidingValues';
import { FounderStory } from './founderStory';
import { Supporters } from './supporters';
import careerImage from '../../assets/about/career_image.webp';
import './index.css';
import Banner from '../../modules/core/banner';
import bannerLogo from '../../assets/about/banner_logo.svg';

export default function AboutScreen() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Banner
        title='The Impact Makers'
        pageTitle='about us'
        subtitle='Striving to make a difference for the environment!'
        className='about-banner-wrapper'
        logotext={bannerLogo}
      />
      <FounderStory />
      <Growth />
      <GuidingValues />
      <Team />
      <Supporters />
      <Career
        image={careerImage}
        containerStyle='bg-brownLight !pb-20 lg:!pb-24 2xl:!pb-32'
        cardStyle='bg-brownMedium about-career-card'
      />
    </>
  );
}
