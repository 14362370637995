import React from 'react';
import ourStoryCollage from '../../../assets/homepage/collage_homepage.webp';
import { ContentTile } from '../../../modules/atoms';
import '../index.css';

const statsData = [
  {
    value: '10,000+',
    subtitle: 'farmers trust Fasal Amrit',
  },
  {
    value: '9+',
    subtitle: 'nations praised the innovation',
  },
  {
    value: '8000+',
    subtitle: 'acres were positively impacted',
  },
  {
    value: '80 crores',
    subtitle: 'litres of water saved',
  },
  {
    value: '5.4 crores',
    subtitle: 'additional income for farmers',
  },
];

const Stats = ({ value, subtitle }) => {
  return (
    <>
      <div className='text-center grid gap-3 2xl:px-6 xl:px-4 px-3'>
        <h3 className='font-plainSemibold text-2xl 2xl:text-[32px] text-white leading-none'>
          {value}
        </h3>
        <p className='font-plainLight max-w-[180px] text-white leading-tight'>{subtitle}</p>
      </div>
    </>
  );
};

const OurStory = () => {
  return (
    <section className={`relative lg:pt-0 blade-pt lg:-mt-[8%] z-40 our-story`}>
      <div
        id='our-story-board'
        className='container rounded-xl lg:bg-white lg:shadow-lg 2xl:p-20 xl:p-12 lg:p-12 md:p-0 z-[40] left-0 right-0'
      >
        <div className='flex lg:pb-24 lg:flex-row animate-box-fade-in flex-col-reverse gap-10 xl:gap-20 items-start md:items-center'>
          <div className='flex-1'>
            <img
              className='w-full h-full object-contain px-3'
              src={ourStoryCollage}
              alt='our-story-collage'
            />
          </div>
          <div className='flex-1'>
            <ContentTile
              label='Our Story'
              center={true}
              className='max-w-3xl xl:max-w-xl lg:!text-left lg:!pr-10 '
              title='Challenging scarcity with sustainability'
              p1='How many times have you skimmed through a newspaper and found headlines describing the horrors of water scarcity in Indian agriculture? It is increasing, threatening, and alarming to millions, especially farmers. '
              p2='One such farmer’s child, Narayan Lal Gurjar viewed these frightening realities as an aspiration. He aimed to invent a solution that could elevate the sustainable conservation of water and agriculture. And he embarked on the journey to create one of the world’s first organic super-absorbent soil moisturizer! He soon created a soil moisturizer from eco-waste that could retain moisture, nourish the soil, and even boost crop growth naturally. This revolutionary soil moisturizer transformed sustainable agriculture and is today known as Fasal Amrit.'
            />
          </div>
        </div>

        <div
          className='2xl:max-w-screen-xl xl:max-w-none lg:absolute w-[85%]
                    bg-greenDark mt-10 md:mt-12 lg:mt-0 justify-center md:gap-y-2 items-center md:items-stretch 
                    flex flex-col md:flex-row flex-wrap lg:flex-nowrap 
      rounded-xl mx-auto top-full right-0 left-0 px-4 2xl:px-10 xl:px-5 2xl:py-10 md:py-7 py-4 
      lg:translate-y-[-50%]'
        >
          {statsData.map((stat, index) => {
            return (
              <div key={index} className='md:flex justify-center max-sm:last:col-span-2 h-auto'>
                <div key={index} className='py-4'>
                  <Stats value={stat.value} subtitle={stat.subtitle} />
                </div>
                {index === statsData.length - 1 ? (
                  ''
                ) : (
                  <span
                    className='block md:h-auto h-[1px] md:my-4 w-auto md:w-[2px] max-w-[100px] mx-auto 
                  bg-[#499F0F] rounded-3xl'
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OurStory;
