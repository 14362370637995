import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual, FreeMode } from 'swiper/core';
// import "swiper/swiper-bundle.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/virtual';
import 'swiper/css/pagination';

import './style.css';
import logo from '../../../assets/globals/efp_logo.svg'
import japanTimes from '../../../assets/homepage/resources/japan-times.svg';
import patrika from '../../../assets/homepage/resources/patrika-logo.webp';
import rjpatrika from '../../../assets/homepage/resources/rajasthan_patrika.webp';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual, FreeMode]);

const slides = [
  {
    imgSrc: japanTimes,
    date: 'Jan 8, 2020',
    title: 'The Japan Times',
    des: 'EF Polymer was in its development stage when the prestigious newspaper ‘The Japan Times’ covered it. The article also communicated the polymer’s formation and OIST’s support. ',
    link: '',
  },
  {
    imgSrc: patrika,
    date: 'Nov 8, 2019',
    title: 'Patrika',
    des: 'The article conveyed the widespread use and recognition of Fasal Amrit in the Netherlands and other countries. This report appreciated EF Polymer for bagging ‘Carbon Take’ award. ',
    link: '',
  },
  {
    imgSrc: rjpatrika,
    date: 'May 8, 2020',
    title: 'Rajasthan Patrika',
    des: 'The organic soil moisturizer was featured in Rajasthan Patrika as a solution to save water and combat drought in agriculture.',
    link: '',
  },
];

export default function Slider() {
  const swiperRef = useRef();

  return (
    <div className='animate-box-fade-in px-5'>
      <Swiper
        ref={swiperRef}
        id='swiper-news'
        virtual
        centeredSlides
        slideNextClass='!transition-all'
        autoplay
        loop
        speed={1200}
        grabCursor
        className='w-auto'
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          280: {
            spaceBetween: 30,
            slidesPerView: 1,
          },
          1024: {
            spaceBetween: 20,
            slidesPerView: 1.3,
          },
          1366: {
            spaceBetween: 60,
            slidesPerView: 1.6,
          },
          1536: {
            spaceBetween: 100,
            slidesPerView: 2,
          },
        }}
      >
        {slides.map((slide, i) => {
          return (
            <SwiperSlide
              key={i}
              className='rounded-3xl overflow-hidden bg-white w-full select-none'
            >
              <div className='xl:h-[25rem] md:h-[20rem] h-[16rem] mx-auto md:flex flex-col items-start justifycenter leaves-lg'>
                {/* <div className='flex-1 h-[15rem] md:h-full w-full '>
                  <img
                    className='w-full h-full object-cover object-top'
                    src={slide.imgSrc}
                    alt={slide.title}
                  />
                </div> */}
                <div className='md:px-10 px-4 flex items-center mt-5 md:mt-10 justify-between w-full gap-5'>
                  <img className={`${i === 0 ? "h-4 md:h-7" : ""} ${i === 1 ? "h-6 md:h-10" : ""} ${i === 2 ? "h-10 md:h-16" : ""}`} src={slide.imgSrc} alt={slide.title} />
                  <img className='max-sm:h-8' src={logo} alt="EF Polymer Logo" />
                </div>
                <div className='flex1 md:p-10 p-4 max-wxs md:max-w-xl'>
                  <p className='opacity-60 font-plainLight'>{slide.date}</p>
                  <h4 className='font-plainBold text-greenDark mt-1'>{slide.title}</h4>
                  <p className='mt-3 font-plainLight leading-snug'>{slide.des}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <div className='flex justify-center items-center md:gap-5 gap-3 mt-16'>
          <button
            aria-label='Swipe to previous slide'
            className='swiper-button-prev rounded-full outline-green outline-offset-1 prev-btn-certificates
              md:pl-2 pl-1 w-6 h-6 hover:stroke-white stroke-greenDark md:p-3 p-2 border-2 border-greenDark hover:bg-greenDark  flex items-center justify-center group 
            '
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='2'
              className='h-6 w-6 xl:h-8 xl:w-8'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
            </svg>
          </button>
          <button
            aria-label='Swipe to next slide'
            className='swiper-button-next outline-green outline-offset-1 prev-btn-certificates rounded-full
              md:pl-2 pl-1 w-6 h-6 rotate-180 hover:stroke-white stroke-greenDark md:p-3 p-2  border-2 border-greenDark hover:bg-greenDark  flex items-center justify-center group 
            '
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='2'
              className='h-6 w-6 xl:h-8 xl:w-8'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
            </svg>
          </button>
        </div>
      </Swiper>
    </div>
  );
}
