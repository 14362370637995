import React from 'react';
import { ContentTile } from '../../../modules/atoms';
import Slider from './slider';

const Testimonials = () => {
  return (
    <section className='container blade-pb-sm lg:blade-pb blade-pt-sm lg:blade-pt'>
      <ContentTile
        label='Testimonials'
        className='max-w-xl mx-auto'
        title='Proud growers'
        p2='Healthy yield, happy farmers! '
        center
        themeDark
      />
      <Slider />
    </section>
  );
};

export default Testimonials;
