import Banner from '../../modules/core/banner';
import bannerLogo from '../../assets/resources/banner_logo.svg';
import { useEffect } from 'react';

import './index.css';
import ResourcesTabs from './resourcesTabs';

const Resources = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Banner
        title='Learn more!'
        pageTitle='RESOURCES'
        subtitle="We aim to incorporate sustainability, conservation, and profitability in every farmer's life."
        logotext={bannerLogo}
        className='resource-banner-wrapper'
      />
      <ResourcesTabs />
    </>
  );
};

export default Resources;
