import React from 'react';
import { ContentTile } from '../../../modules/atoms';
import Cards from './cards';
import ProductsView from './products-view';

const Products = () => {
  return (
    <section className='container blade-pt-sm lg:blade-pt blade-pb'>
      <ContentTile
        label='THE PRODUCT'
        className='max-w-xl mx-auto'
        center
        title='Amrit for plants'
        p2='Farmers and growers can rebuild the earth quicker than anyone else. Contributing to their efforts, EF Polymer created a solution that would promote sustainable agriculture, save natural resources, and help farmers grow a better yield.'
        p4='What would you call such a miraculous innovation? <br class="md:block hidden "/> We call it Fasal Amrit; the Amrit for Plants!'
      />

      <ProductsView />
      <h3 className='font-plainSemibold mb-8 lg:mb-12 mt-10 2xl:mt-20 md:mt-14 lg:mt-16 xl:text-[32px] 2xl:text-[36px] text-[28px] leading-tight text-center text-greenDark'>
        Why use Fasal Amrit?
      </h3>
      <Cards />
    </section>
  );
};

export default Products;
