import React from 'react';
import ReactMarkdown from 'react-markdown';

function Heading1({ children }) {
  return <h1 className='font-plainBold text-black'>{children}</h1>;
}

function Heading2({ children }) {
  return <h2 className='font-plainBold'>{children}</h2>;
}

function Heading3({ children }) {
  return (
    <h3 className='font-plainSemibold mt-8 text-[20px] md:text-[28px] xl:text-[32px]'>
      {children}
    </h3>
  );
}

function Heading4({ children }) {
  return <h4 className='pt-6 md:pt-8 lg:pt-12  pb-3 md:pb-5 font-plainSemibold'>{children}</h4>;
}

function Heading5({ children }) {
  return <h5 className='font-plainSemibold pb-1 pt-3'>{children}</h5>;
}
function Heading6({ children }) {
  return <h6 className='font-plainNormal tracking-wide pb-1 pt-4  '>{children}</h6>;
}

function ListItem({ children }) {
  return <li className='text-base font-regular  md:text-lg pb-2 '>{children}</li>;
}
function OrderedList({ children }) {
  return (
    <ol className='leading-relaxed pb-3  text-base  md:text-lg  pl-6 list-decimal'>{children}</ol>
  );
}

function UnorderedList({ children }) {
  return (
    <ul className='leading-relaxed pb-3  text-base  md:text-lg  pl-6 list-disc'>{children}</ul>
  );
}

function Para({ children }) {
  return <p className='leading-relaxed pt-3 font-plainNormal text-base md:text-lg'>{children}</p>;
}

const markdownConfig = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
  p: Para,
  a: Anchor,
  li: ListItem,
  ul: UnorderedList,
  ol: OrderedList,
};

function Anchor(props) {
  const { children } = props;
  return (
    <a
      {...props}
      target='_blank'
      rel='noreferrer'
      className='underline font-medium tracking-wide  text-green decoration-from-font underline-offset-2 lowercase '
    >
      {children}
    </a>
  );
}
export { markdownConfig };

export default function RenderMarkdown({ content }) {
  return <ReactMarkdown components={markdownConfig}>{content}</ReactMarkdown>;
}
