import { EcoFriendlyHydrogel } from './ecoFriendlyHydrogel';
import { Impact } from './impact';
import { Steps } from './steps';
import { Testimonials } from './testimonials';
import { FAQ } from './faq';
// import { Applications } from './applications';
import Banner from '../../modules/core/banner';
import BannerLogo from '../../assets/agriculture/banner_logo.svg';
import GetInTouch from '../../modules/core/get-in-touch';
import './index.css';
import { useEffect } from 'react';
import { Benefits } from './benefits';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const Agriculture = () => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(window, { scrollTo: 0 });
    });

    return () => ctx.revert();
  }, []);
  return (
    <>
      <Banner
        title='Empowering growers'
        subtitle='Enhance your plant’s growth organically.'
        pageTitle='Agriculture'
        logotext={BannerLogo}
        className='agriculture-banner-wrapper'
      />
      <EcoFriendlyHydrogel />
      <Impact />
      <Steps />
      <Benefits />
      {/* <Applications /> */}
      <Testimonials />
      <FAQ />
      <GetInTouch bg='#F1EDE6' />
    </>
  );
};

export default Agriculture;
