import ContentTile from '../../modules/atoms/contentTile';
import productView from '../../assets/agriculture/eco-friendly-hydrogel/fasal-product-growers.webp';

// import Amazon from '../../assets/agriculture/eco-friendly-hydrogel/amazon_logo.webp';
import BigHaat from '../../assets/agriculture/eco-friendly-hydrogel/bighaat_logo.webp';
// import Flipkart from '../../assets/agriculture/eco-friendly-hydrogel/flipkart_logo.webp';

import './index.css';

export const EcoFriendlyHydrogel = () => {
  return (
    <div className='flex flex-col lg:flex-row items-center'>
      <div
        className='bg-brownMedium blade-pt-sm lg:pt-0 px-4 sm:px-12 lg:px-0 w-full lg:w-[50%] xl:w-[45%] 2xl:w-[55%] h-auto lg:h-[760px] 
      xl:h-[850px] flex items-center lg:justify-center'
      >
        <div className='relative flex md:p-8 lg:p-10 justify-center items-center'>
          <div>
            <img src={productView} alt='Front view of the product' />
          </div>
        </div>
      </div>

      <div
        className='eco-friendly-right-container w-full lg:w-[50%] xl:w-[55%] 2xl:w-[45%] lg:self-stretch pt-4 md:pt-6 lg:pt-20 xl:pt-32 
      blade-pb-sm lg:blade-pb px-4 sm:px-12 xl:px-20'
      >
        <ContentTile
          title='Introducing Fasal Amrit'
          themeDark={window.innerWidth < 1024 ? false : true}
          center={window.innerWidth > 1024 ? false : true}
          className='lx-auto md:max-w-2xl'
          p2='A sustainable solution to growing a healthy plant, profitable yield, maintaining water retention and benefits the environment simultaneously. Created using organic waste, Fasal Amrit can be your one-stop solution to reduce the use of chemicals and water, boost plant growth naturally, and increase profits.'
        />

        <p className='lg:text-left text-center lg:text-yellow text-greenDark  mt-5 lg:mt-16 font-plainSemibold text-lg md:text-xl'>
          Get your pack now!
        </p>

        <div
          className='md:mx-0 mx-auto w-full grid
        mt-2 lg:mt-6 justify-center lg:justify-start sm:items-center gap-y-4 gap-x-2 sm:gap-x-6'
        >
          {/* <a
            href="https://www.amazon.in/Biodegradable-Fasal-Amrit-Hydrogel-Agriculture/dp/B09NDB6S4K/[%E2%80%A6]J4gQAXCh1xtbhi-pwBB-8C1Bk-WlfBEWdAsmJJL9bvHhoCJ2UQAvD_BwE&th=1"
            target="_blank"
          >
            <img
              className="w-full sm:w-40 xl:w-40"
              src={Amazon}
              alt="amazon logo"
            />
          </a> */}
          <a
            href='https://www.bighaat.com/products/fasal-amrit?pf=search'
            target='_blank'
            rel='noreferrer'
            aria-label='Bighaat logo'
            className='w-[120px] lg:w-[160px]'
          >
            <img className='w-full sm:w-40 xl:w-40' src={BigHaat} alt='Bighaat logo' />
          </a>
          {/* <a
            href="https://www.flipkart.com/fasal-amrit-100-organic-biodegradable-hydrogel-home-plant-gardening-potting-mixture/p/itma9e6a70bd41a1?pid=SMNGTHJ6JQGU84XP&lid=LSTSMNGTHJ6JQGU84XP0GOUGX&marketplace=FLIPKART&q=ef+polymer+fasal+amrit&store=search.flipkart.com&srno=s_1_2&otracker=search&otracker1=search&fm=Search&iid=3f2fbbe4-dac8-4e2f-a9e9-a724b7a64078.SMNGTHJ6JQGU84XP.SEARCH&ppt=sp&ppn=sp&ssid=dxaw8xig5c0000001700733454399&qH=ba728ec6cc3169aa"
            target="_blank"
          >
            <img
              className="w-full sm:w-40 xl:w-40"
              src={Flipkart}
              alt="flipkart logo"
            />
          </a> */}
        </div>

        <div className='mt-4 sm:mt-6 flex flex-col items-center justify-center lg:items-start'>
          <h5 className='font-plainSemibold text-lg md:text-xl text-greenDark lg:text-yellow'>
            Call on:
          </h5>
          <p className='font-plainLight text-base lg:text-lg lg:text-white mt-1'>
            <a aria-label='Call us at 9929742022' href='tel:+919929742022'>
              9929742022
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
