import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { gsap } from 'gsap';
import { SwitchTransition, Transition } from 'react-transition-group';
import 'swiper/css';
import './style.css';
import 'swiper/css/navigation';

// Assets
import bgLine from './bg-line.svg';
import sambhusingh from '../../../assets/homepage/testimonials/sambhu.webp';
import sohansingh from '../../../assets/homepage/testimonials/sohansingh.webp';
import jetubai from '../../../assets/homepage/testimonials/jetubai.webp';
import khumaji from '../../../assets/homepage/testimonials/khumaji.webp';
import bansidhar from '../../../assets/homepage/testimonials/bansidhar.webp';
import ramesh from '../../../assets/homepage/testimonials/ramesh.webp';
import shyamlal from '../../../assets/homepage/testimonials/shyamlal.webp';

// Crop img
import radish from '../../../assets/homepage/testimonials/cropImg/radish.webp';
import tomato from '../../../assets/homepage/testimonials/cropImg/tomato.webp';
import wheat from '../../../assets/homepage/testimonials/cropImg/wheat.webp';
import maize from '../../../assets/homepage/testimonials/cropImg/maize.webp';

const testimonials = [
  {
    img: sambhusingh,
    name: 'Sambhu Singh',
    cropimg: maize,
    crop: 'Maize',
    village: 'Bansiwara',
    youtubeLink:
      'https://www.youtube.com/watch?v=-LB2jzzVDb0&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=27',
    statement:
      '“I used Fasal Amrit and my crops survived for longer periods without water. I will definitely use it again in the future.”',
  },
  {
    img: sohansingh,
    name: 'Sohan Singh',
    cropimg: maize,
    crop: 'Maize',
    village: 'Bansiwara',
    youtubeLink:
      'https://www.youtube.com/watch?v=7z7AfqkP8Fs&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=28',
    statement:
      '“Fasal Amrit has helped me keep the soil moist and soft and has helped maize crops grow for more than 15-20 days without water.”',
  },
  {
    img: jetubai,
    name: 'Jetu Bai',
    cropimg: wheat,
    // crop: 'Wheat',
    // village: 'Rajsamand',
    occupation: 'Forestry labour',
    youtubeLink:
      'https://www.youtube.com/watch?v=TpsPxEX2NfA&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=23',
    statement:
      '“Fasal Amrit has made my plants tall and dense, helping them retain water after heavy rains. Even my new plants remain unaffected by dry winds blowing in the area.”',
  },
  {
    img: khumaji,
    name: 'Khuma Ji',
    cropimg: tomato,
    // crop: 'Tomato',
    // village: 'Rajsamand',
    occupation: 'Forestry labour',
    youtubeLink:
      'https://www.youtube.com/watch?v=1Id7_wcYDRE&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=24',
    statement:
      '“There has been no rain in the area for more than 40 days, yet my plants have grown taller and better.”',
  },
  {
    img: bansidhar,
    name: 'Banshi Dhar Sharma',
    cropimg: radish,
    // crop: 'Radish and Tomato',
    // village: 'Dausa',
    organization: 'Arpan Seva Sansthan',
    youtubeLink:
      'https://www.youtube.com/watch?v=hRDOjCtaTCM&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=25',
    statement:
      '“Fasal Amrit helped me maintain a balance between water and moisture levels in the soil. It also helped my plants grow better and live longer.”',
  },
  {
    img: ramesh,
    name: 'Ramesh Chand Sharma',
    cropimg: wheat,
    // crop: 'Wheat',
    // village: 'Madhopur',
    organization: 'Arpan Seva Sansthan',
    youtubeLink:
      'https://www.youtube.com/watch?v=mwYup4rmQg8&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=26',
    statement:
      '“With the help of Fasal Amrit, I was able to help my plants grow better even with less water.”',
  },
  {
    img: shyamlal,
    name: 'Shyamlal Dangi',
    cropimg: tomato,
    crop: 'Tomato',
    village: 'Mirzapur',
    youtubeLink:
      'https://www.youtube.com/watch?v=17W_BG948SI&list=PLWBP8RwYJuR6fHeU1kAa97mupkejhXCW0&index=21',
    statement:
      '“Recently, with Fasal Amrit, my tomato profits increased by almost 45%! My tomatoes grew bigger, taller, and I used 15% less water.”',
  },
];

export default function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState(testimonials);

  const exitAnim = (node) => {
    gsap.to(node, {
      duration: 0.6,
      yPercent: 10,
      opacity: 0,
      delay: 0.1,
    });
  };

  const enter = (node) => {
    gsap.fromTo(
      node,
      {
        opacity: 0,
        yPercent: 10,
      },
      {
        yPercent: 0,
        duration: 0.6,
        opacity: 1,
        delay: 0.1,
      },
    );
  };

  function handleActiveState(s) {
    setActiveIndex(s.activeIndex);
  }

  const swiper = useRef();

  useEffect(() => {
    if (activeIndex % 4 === 0 && swiper?.current) {
      const newData = [...data, ...testimonials];
      setData(newData);
    }
  }, [activeIndex, swiper]);

  return (
    <section className='md:pt-10 pt-2'>
      <section className='animate-box-fade-in'>
        <section className='carousel-wrapper overflow-hidden relative px-2 w-full md:px-6 md:w-[96%] lg:w-11/12 xl:w-9/12 2xl:w-[70%] lg:max-w-screen-2xl mx-auto'>
          <div className='flex my-5 md:my-8 2xl:h-[180px] xl:h-[130px] h-[140px] items-center absolute bottom-0 top-0 left-0 z-0 '>
            <div className='w-full h-full mx-auto grid place-content-center'>
              <img className='h-full w-full' src={bgLine} alt='carousel bg' aria-hidden />
            </div>
          </div>

          <div className='testimonials-slider'>
            <Swiper
              cssMode={true}
              initialSlide={window.innerWidth > 640 ? 2 : 0}
              ref={swiper}
              navigation={{
                nextEl: '.testimonials-next-btn',
                prevEl: '.testimonials-prev-btn',
              }}
              slideToClickedSlide={true}
              onSlideChange={(swiper) => handleActiveState(swiper)}
              slideActiveClass='slide-active-testimonials'
              creativeEffect
              centeredSlides={true}
              speed={2000}
              className='!w-full !flex !place-content-center'
              slidesPerView='1'
              spaceBetween='5'
              breakpoints={{
                640: {
                  slidesPerView: 2.8,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1536: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1860: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
            >
              {data.map((elem, index) => {
                return (
                  <SwiperSlide className='py-8 !flex !justify-center !items-center' key={index}>
                    <div className='img-wrapper cursor-pointer flex justify-center items-center border border-white min-h-full 2xl:h-[160px] 2xl:w-[160px] md:h-[120px] md:w-[120px] h-[110px] w-[110px] rounded-full overflow-hidden'>
                      <img
                        src={elem.img}
                        className='slide-image transition-all h-full w-full object-top object-cover duration-500 rounded-full'
                        alt={elem.name}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div
            className='bg-white relative grid place-content-center rounded-3xl text-gradient h4 
          2xl:max-w-screen-md max-w-xl 2xl:p-10 xl:pb-7 xl:pt-4 xl:px-6 p-8  mx-auto 2xl:mt-12 md:mt-6 mt-2 text-center'
          >
            <SwitchTransition>
              <Transition
                appear={true}
                timeout={300}
                in={true}
                key={activeIndex}
                onExit={exitAnim}
                onEnter={enter}
                mountOnEnter
                unmountOnExit
              >
                <>
                  <div className=''>
                    <h4 className='font-plainBold text-greenDark'>{data[activeIndex].name}</h4>
                    <p className='text-greenDark max-w-2xl 2xl:max-w-xl font-plainNormal mt-3'>
                      {data[activeIndex].statement}
                    </p>
                    <div className='max-w-[60%] mx-auto my-5 h-[1px] bg-gradient-to-r from-white via-slate-400 to-white'></div>
                    <div className='flex md:flex-row flex-col justify-center gap-y-4 md:gap-10'>
                      {data[activeIndex].village && (
                        <p className='md:text-lg text-sm font-plainNormal opacity-90'>
                          Village: {data[activeIndex].village}
                        </p>
                      )}
                      {data[activeIndex].crop && (
                        <div className='flex items-center justify-center md:items-start gap-x-2'>
                          <p className='md:text-lg text-sm font-plainNormal opacity-90'>
                            Crop: {data[activeIndex].crop}
                          </p>
                          <div className='md:-translate-y-4'>
                            <img
                              className='w-12 md:w-16 object-contain'
                              src={data[activeIndex].cropimg}
                              alt={data[activeIndex].crop}
                            />
                          </div>
                        </div>
                      )}
                      {data[activeIndex].occupation && (
                        <div className='flex items-center justify-center md:items-start gap-x-2 pb-2 md:pb-10'>
                          <p className='md:text-lg text-sm font-plainNormal opacity-90'>
                            Occupation: {data[activeIndex].occupation}
                          </p>
                        </div>
                      )}
                      {data[activeIndex].organization && (
                        <div className='flex items-center justify-center md:items-start gap-x-2 pb-2 md:pb-10'>
                          <p className='md:text-lg text-sm font-plainNormal opacity-90'>
                            Organization: {data[activeIndex].organization}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className='mt-6 md:mt-0'>
                      <a
                        aria-label='Watch the full video'
                        className='cursor-pointer text-greenDark border-greenDark border bg-transparent 
                        hover:bg-greenDark hover:text-white outline-green outline-offset-4
                        font-plainNormal px-8 py-3 rounded-full'
                        href={data[activeIndex].youtubeLink}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Watch the full video
                      </a>
                    </div>
                  </div>
                </>
              </Transition>
            </SwitchTransition>
          </div>

          <div className='flex justify-center gap-4 items-center p-3 mt-6'>
            <button
              aria-label='Go to previous slide'
              className={`${
                activeIndex === 0
                  ? 'opacity-30 hover:border-white hover:stroke-white'
                  : 'opacity-100'
              } hover:stroke-yellow outline-yellow outline-offset-0 stroke-white p-3 testimonials-prev-btn rounded-full border-2 border-white hover:border-yellow flex items-center justify-center`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2'
                stroke='inherit'
                className='w-6 h-6 stroke-inherit'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15.75 19.5L8.25 12l7.5-7.5'
                />
              </svg>
            </button>
            <button
              aria-label='Go to next slide'
              className={`${
                activeIndex === data.length - 1
                  ? 'opacity-30 hover:border-white hover:stroke-white'
                  : 'opacity-100'
              }  hover:stroke-yellow outline-yellow outline-offset-0 stroke-white testimonials-next-btn p-3 rounded-full border-2 border-white hover:border-yellow flex items-center justify-center`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2'
                stroke='inherit'
                className='w-6 h-6 stroke-inherit'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
              </svg>
            </button>
          </div>
        </section>
      </section>
    </section>
  );
}
