import ContentTile from '../../modules/atoms/contentTile';
import Logo1 from '../../assets/about/supporters/logo_bill&melinda.webp';
import Logo2 from '../../assets/about/supporters/logo_imagineh20.webp';
import Logo3 from '../../assets/about/supporters/logo_marico.webp';
import Logo4 from '../../assets/about/supporters/logo_niam.webp';
import Logo5 from '../../assets/about/supporters/logo_oist.webp';
import Logo6 from '../../assets/about/supporters/logo_pwc.webp';
import Logo7 from '../../assets/about/supporters/logo_socialalpha.webp';
import Logo8 from '../../assets/about/supporters/logo_tata trusts.webp';
import Logo9 from '../../assets/about/supporters/logo_sewa.webp';
import Logo10 from '../../assets/about/supporters/logo_psss.webp';
import Logo11 from '../../assets/about/supporters/logo_baif.webp';

const logos = [
  {
    id: 1,
    url: Logo1,
    alt: 'Bill Gates and Melinda foundation logo',
    link: 'https://www.gatesfoundation.org/',
  },
  {
    id: 2,
    url: Logo2,
    alt: 'Imagine H2O logo',
    link: 'https://www.linkedin.com/feed/update/urn:li:activity:7140946652739133440',
  },
  {
    id: 3,
    url: Logo3,
    alt: 'Marico logo',
    link: 'https://www.linkedin.com/posts/marico-innovation-foundation_mentorshipmatters-innovation-entrepreneurship-activity-7095387188934152192-kRXO?trk=public_profile_like_view',
  },
  {
    id: 4,
    url: Logo4,
    alt: 'NIAM logo',
    link: 'https://www.linkedin.com/posts/niam-agri-business-incubator-nabi-2853b1180_startup-funding-agriculture-activity-7062393806943379457-26R_',
  },
  {
    id: 5,
    url: Logo5,
    alt: 'OIST logo',
    link: 'https://www.oist.jp/news-center/news/2022/3/9/oist-grown-company-ef-polymer-wins-top-prize-japans-2021-green-startup-pitch#:~:text=Now%20headquartered%20in%20the%20incubator,parts%20of%20fruits%20and%20vegetables',
  },
  { id: 6, url: Logo6, alt: 'PWC logo', link: 'https://www.pwc.com/gx/en.html' },
  {
    id: 7,
    url: Logo7,
    alt: 'Social alpha logo',
    link: 'https://www.linkedin.com/posts/ef-polymer-india_agritech-startups-agriculture-activity-7107757438526717952-HQOz/',
  },
  { id: 8, url: Logo8, alt: 'Tata trusts logo', link: 'https://www.tatatrusts.org/' },
  { id: 9, url: Logo9, link: 'https://www.sevamandir.org/', alt: 'Seva Sadhna Kranti logo' },
  { id: 10, url: Logo10, link: 'https://www.psssonline.com/', alt: 'PSSS logo' },
  { id: 11, url: Logo11, link: 'https://baif.org.in/', alt: 'BIAF logo' },
];

export const Supporters = () => {
  return (
    <div className='blade-pt-sm xl:blade-pt blade-pb-sm  bg-brownLight'>
      <div className='container'>
        <ContentTile
          label='Our Supporters'
          center
          className='mx-auto max-w-2xl'
          title='Backbone of the revolution'
          p2='“Is it possible?” Their support makes us say yes! <br/> Our active network of visionary organizations, investors, and experts inspires us to achieve the unthinkable.'
        />

        <div className='grid grid-cols-2 sm:grid-cols-[38%_38%] md:grid-cols-3 lg:flex mt-8 md:mt-10 lg:flex-row lg:flex-wrap items-center justify-center gap-2 md:gap-4 2xl:gap-6 '>
          {logos.map((logo, index) => (
            <a
              key={index}
              href={logo.link}
              target='_blank'
              rel='noreferrer'
              aria-label={logo.alt}
              className={`bg-white flex group outline-green items-center justify-center py-4 rounded-xl ${
                logo.id === logos.length ? 'translate-x-[50%] md:translate-x-0' : ''
              }`}
            >
              <img
                src={logo.url}
                alt={logo.alt}
                className='group-hover:scale-105 transition-all duration-500'
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
