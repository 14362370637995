import { useEffect } from "react";
import RenderMarkdown from "../modules/atoms/renderMarkdown";

const content = `

At [efpolymer.com](https://efpolymer.in/), accessible from efpolymer.in, the privacy of our visitors is a top priority. This Privacy Policy document details the types of information collected and recorded by efpolymer.com and how we utilize it. If you have further questions or need additional information about our Privacy Policy, feel free to contact us.


### Log Files
efpolymer.com follows the standard procedure of using log files. These files log visitors when they access websites. This information includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. It is not linked to any personally identifiable information. The purpose of this information is to analyze trends, administer the site, track users' movement on the website, and gather demographic information.

### Cookies and Web Beacons
Like any other website, efpolymer.com uses "cookies" to store information, including visitors' preferences and the pages they accessed or visited. This information is used to optimize users' experiences by customizing web page content based on visitors' browser type and/or other information. For more general information on cookies, please read the "Cookies" article from the [Privacy Policy Generator](https://www.privacypolicyonline.com/what-are-cookies/).

### Google DoubleClick DART Cookie
Google, as a third-party vendor on our site, uses cookies known as DART cookies to serve ads to site visitors based upon their visit to [www.website.com](http://www.website.com/) and other sites on the internet. Visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at [https://policies.google.com/technologies/ads](https://policies.google.com/technologies/ads).

### Privacy Policies
Consult this list to find the Privacy Policy for each advertising partner of efpolymer.com. Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons in their respective advertisements and links on efpolymer.com. These technologies measure the effectiveness of advertising campaigns and/or personalize the advertising content seen on visited websites. efpolymer.com has no access to or control over cookies used by third-party advertisers.

### Third Party Privacy Policies
efpolymer.com's Privacy Policy does not apply to other advertisers or websites. Therefore, consult the respective Privacy Policies of these third-party ad servers for more detailed information, including practices and instructions on opting out. You can choose to disable cookies through your individual browser options. For detailed information about cookie management with specific web browsers, refer to the browsers' respective websites.

### Children's Information
Protecting children while using the internet is a priority. Parents and guardians are encouraged to observe, participate in, and/or monitor and guide their online activity. efpolymer.com does not knowingly collect Personal Identifiable Information from children under the age of 13. If your child provided such information on our website, contact us immediately, and we will promptly remove it from our records.

### Online Privacy Policy Only
This Privacy Policy applies only to our online activities and is valid for visitors to our website regarding the information shared and/or collected on efpolymer.com. This policy does not apply to information collected offline or via channels other than this website.

### Consent
By using our website, you consent to our Privacy Policy and agree to its Terms and Conditions.
`;

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="blade-pt blade-pb container">
      <h2 className="font-plainSemibold mt-12 text-center">Privacy Policy</h2>

      <div className="pt-4 md:pt-6 xl:pt-10 2xl:pt-16">
        <RenderMarkdown content={content} />
      </div>
    </div>
  );
}
