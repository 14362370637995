import Image1 from '../resources/awards/image-01.webp';
import Image2 from '../resources/awards/image-02.webp';
import Image3 from '../resources/awards/image-03.webp';
import Image4 from '../resources/awards/image-04.webp';
import Image5 from '../resources/awards/image-05.webp';
import Image6 from '../resources/awards/image-06.webp';
import Image7 from '../resources/awards/image-07.webp';
import Image8 from '../resources/awards/image-08.webp';
import Image9 from '../resources/awards/image-09.webp';
import Image10 from '../resources/awards/image-10.webp';

import image11 from '../resources/certificates/EU-type.webp';
import image12 from '../resources/certificates/Ecocert-1.webp';
import image13 from '../resources/certificates/Ecocert-2.webp';
import image14 from '../resources/certificates/materialist.webp';

export const awards = [
  {
    id: 1,
    name: 'Best Innovation Award at President House of India in 2018',
    url: Image1,
    isAward: true,
  },
  {
    id: 2,
    name: 'Best Student Innovation Award in 9th DST-CII-AICTE India Innovation Initiative-Ci31 2017',
    url: Image2,
    isAward: true,
  },
  {
    id: 3,
    name: 'Challenge for Change Award from GoR in 2017',
    url: Image3,
    isAward: true,
  },
  {
    id: 4,
    name: 'Corbon Tech Winner of Climate Launchpad Global Grand Finale in 2019 in The Netherlands',
    url: Image4,
    isAward: true,
  },
  {
    id: 5,
    name: 'G20 2nd rank in agritech',
    url: Image5,
    isAward: true,
  },
  {
    id: 6,
    name: 'JA Accelerator 3rd Term Excellence Award',
    url: Image6,
    isAward: true,
  },
  {
    id: 7,
    name: 'Maharana Raj Singh Award',
    url: Image7,
    isAward: true,
  },
  {
    id: 8,
    name: 'Winner of Best Startup in Environment Category from The Bank of Okinawa',
    url: Image8,
    isAward: true,
  },
  {
    id: 9,
    name: 'Winner of Stuprenerurs Award from TiE Udaipur and Startup Oasis in 2017',
    url: Image9,
    isAward: true,
  },
  {
    id: 10,
    name: 'ASCENSION PITCH - Asia Next Unicorn Award at ASCENSION 2023 x StartupGO!GO!',
    url: Image10,
    isAward: true,
  },
];

export const certificates = [
  {
    id: 1,
    name: 'EU type examination certificate',
    url: image11,
    isCertificate: true,
  },
  {
    id: 2,
    name: 'Certificate by Ecocert S.A.S (EU)',
    url: image12,
    isCertificate: true,
  },
  {
    id: 3,
    name: 'Certificate by Ecocert S.A.S (USDA NOP)',
    url: image13,
    isCertificate: true,
  },
  {
    id: 4,
    name: 'Organic Japanese Agricultural Standard',
    url: image14,
    isCertificate: true,
  },
];
