import React, { useState } from 'react';

import { caseStudies } from '../../assets/data/caseStudies';
import { awards } from '../../assets/data/awardsAndCertificates';
import { certificates } from '../../assets/data/awardsAndCertificates';
import { pressReleases } from '../../assets/data/pressReleases';
import { PressCard } from './PressCard';
import { CaseStudyCard } from './caseStudyCard';
import { AwardCard } from './awardCard';
import { CertificateCard } from './awardCard';

const tabs = [
  { id: 2, name: 'Case studies' },
  { id: 3, name: 'Awards & certificates' },
  { id: 1, name: 'Press & media' },
];

const activeTabData = {
  'Case studies': { data: caseStudies, card: CaseStudyCard },
  'Awards & certificates': {
    data: awards,
    data2: certificates,
    card: AwardCard,
    card2: CertificateCard,
  },
  'Press & media': { data: pressReleases, card: PressCard },
};

const ResourcesTabs = () => {
  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: 'Press & media',
  });

  return (
    <div className='blade-pt-sm lg:blade-pt bg-brownLight blade-pb'>
      <h2
        id='text'
        className='text-greenDark max-w-2xl text-center mx-auto font-plainBold leading-tight tracking-tight'
      >
        We don't just talk, <br /> we speak through results!
      </h2>
      <div className='w-full mt-5 md:mt-14 2xl:mt-20 flex flex-wrap flex-row gap-y-2 md:gap-y-4 px-2 lg:px-12 justify-center gap-x-2 lg:gap-x-12 relative'>
        {tabs.map((tab) => (
          <button
            aria-label={tab.name}
            key={tab.id}
            onClick={() => setActiveTab(tab)}
            className={`${
              activeTab.id === tab.id && 'bg-greenDark'
            }  flex items-center md:justify-center cursor-pointer outline-green outline-offset-4 hover:bg-greenDark group rounded-full lg:rounded-[16px] px-4 md:px-5 lg:px-12 py-2 md:py-3 
              transition-all duration-300 ease-in-out`}
          >
            <p
              aria-hidden='true'
              className={`group-hover:opacity-100 pb-1 group-hover:text-white text-black font-plainNormal cursor-pointer text-base md:text-xl transition-all duration-300 ease-in-out ${
                activeTab.id === tab.id ? 'opacity-100 text-white' : 'opacity-50'
              }`}
            >
              {tab.name}
            </p>
          </button>
        ))}
      </div>

      {activeTab.name !== 'Awards & certificates' ? (
        <div className='grid sm:grid-cols-2 lg:grid-cols-3 max-w-screen-2xl mx-auto px-4 lg:px-6 xl:px-12 mt-4 md:mt-8 lg:mt-14 gap-x-6 gap-y-8'>
          {activeTabData[activeTab.name].data.map((item) => {
            const CardComponent = activeTabData[activeTab.name].card;
            return <CardComponent key={item.id} {...item} />;
          })}
        </div>
      ) : (
        <div className='max-w-screen-2xl mx-auto px-4 lg:px-6 xl:px-12 mt-4 md:mt-8 lg:mt-14'>
          <h3 className='text-greenDark font-plainBold leading-tight tracking-tight'>
            Certificates
          </h3>
          <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-8 mt-4 lg:mt-8'>
            {activeTabData[activeTab.name].data2.map((item) => {
              const CardComponent = activeTabData[activeTab.name].card2;

              return <CardComponent key={item.id} {...item} />;
            })}
          </div>
          <h3 className='text-greenDark font-plainBold leading-tight tracking-tight mt-8 lg:mt-12'>
            Awards
          </h3>
          <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8 mt-4 lg:mt-8'>
            {activeTabData[activeTab.name].data.map((item) => {
              const CardComponent = activeTabData[activeTab.name].card;

              return <CardComponent key={item.id} {...item} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourcesTabs;
