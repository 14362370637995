import React from 'react';
import { ContentTile } from '../../../modules/atoms';
import Slider from './slider';

const ResourcesNews = () => {
  return (
    <>
      <section className='container blade-pt-sm'>
        <ContentTile
          className='max-w-xl mx-auto'
          center
          title='EF Polymer in news'
          p2='Grateful for the features!'
        />
      </section>
      <div className='md:mt-10 mt-8 xl:mt-12 blade-pb-sm'>
        <Slider />
      </div>
    </>
  );
};

export default ResourcesNews;
