import Image1 from '../resources/caseStudies/jay_narayan.webp';
import Image2 from '../resources/caseStudies/santosh_singh.webp';
import Image3 from '../resources/caseStudies/ajay_kumar.webp';
import Image4 from '../resources/caseStudies/viran_devi.webp';
import Image5 from '../resources/caseStudies/ramdas.webp';
import Image6 from '../resources/caseStudies/image-06.webp';
import Image7 from '../resources/caseStudies/image-07.webp';
import Image8 from '../resources/caseStudies/image-08.webp';

export const caseStudies = [
  {
    id: 1,
    label: 'Agriculture',
    title: 'Mr. Jay Narayan increased his yield by 3 quintals just by using Fasal Amrit.',
    des: `The farmer from UP cultivated wheat on his 5-acre land and used Fasal Amrit. As a result, he decreased the demand for fertilizers by 15 kgs and increased his yield by 3 quintals. With Fasal Amrit, he could successfully increase his total average income by 29.5%. 
    `,
    imgSrc: Image1,
  },
  {
    id: 2,
    label: 'Agriculture',
    title: `Mr Santosh Singh experienced a 3.48% increase in plant count and 3.87% in plant height by using Fasal Amrit.`,
    des: `The farmer from Jhansi cultivated wheat on his 1-acre land and used Fasal Amrit. His need of fertiliser was reduced by 16.66% in turn reducing the cost of cultivation, ensuring more returns. His total average income increase is reported around 29.49% in wheat cultivation.
    `,
    imgSrc: Image2,
  },
  {
    id: 3,
    label: 'Agriculture',
    title: `Mr Ajay Kumar from Jhansi was able to increase his average income by 29.49% after using Fasal Amrit.
  `,
    des: `Fasal Amrit helped reduce the leaching losses hence making it for him to grow crops easily. There was a total increase of 3.018% in plant count and 2.54% in plant height being observed. 25% of Yield increment was observed.
    `,
    imgSrc: Image3,
  },
  {
    id: 4,
    label: 'Agriculture',
    title: `Smt. Viran Devi, from Jalaun, witnessed a significant 22.22% increase in her crop yield upon implementing Fasal Amrit.
  `,
    des: `Cultivating crops on her 1.5 bigha land, she adopted Fasal Amrit for her farming practices. As a result, she achieved an overall improvement of 6.11% in plant count and a remarkable 16.87% increase in plant height.
    `,
    imgSrc: Image4,
  },
  {
    id: 5,
    label: 'Agriculture',
    title: `Fasal Amrit boosted Mr. Ramdas's income by almost 30% in wheat cultivation.`,
    des: `After using Fasal Amrit on his 1-acre land, Mr. Ramdas successfully reduced his leaching losses. Additionally, he witnessed 13% more yield and his crops also grew 26% taller.`,
    imgSrc: Image5,
  },
  {
    id: 6,
    label: 'Agriculture',
    title: `Mr. Munna Lal achieved a 60% increase in yield with Fasal Amrit.`,
    des: `Fasal Amrit helped reduce the leaching losses and achieve a 5% decrement in fertilizer use. As an impact of this, it got easier for Munna Lal 
    to grow and increase his average income by a whopping 17%.`,
    imgSrc: Image6,
  },
  {
    id: 7,
    label: 'Agriculture',
    title: `Mr. Amar Singh grew his income by 30% with Fasal Amrit.`,
    des: `Apart from reducing irrigation rounds, Fasal Amrit also helped this Hamirpur farmer increase the fertility of the soil by adding up micronutrients. 
    This contributed to higher income and an increase in the fertility and organic component of soil. `,
    imgSrc: Image7,
  },
  {
    id: 8,
    label: 'Agriculture',
    title: `Mr. Kunwar Singh observed almost 45% decrease in irrigation, 40% yield boost.`,
    des: `Since the third round of irrigation, the farmer observed a significant difference in the duration of irrigation. 
    There was a total increase of 10.40% in plant count and 21.40% in plant height. This led to 5% reduction in the cost of cultivation 
    ensuring higher returns.`,
    imgSrc: Image8,
  },
];
