import React from 'react';
import { ContentTile } from '../../../modules/atoms';
import globalMap from '../../../assets/homepage/locations/global-map.webp';

const Locations = () => {
  return (
    <section className='container blade-pb-sm lg:blade-pb blade-pt-sm lg:blade-pt'>
      <ContentTile
        label='LOCATIONS'
        className='max-w-xl mx-auto'
        center
        title='Made in India, valued worldwide'
        p2='From a small village in India to the globe! EF Polymer has become a force of sustainability, helping farmers and businesses become more eco-friendly!'
      />
      <div className='blade-mt-sm md:px-10'>
        <img
          className='w-full h-full object-cover'
          src={globalMap}
          alt='Map showing our locations worldwide'
        />
      </div>
    </section>
  );
};

export default Locations;
