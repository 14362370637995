import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import fasalAmritVid from '../../assets/agriculture/benefits/fasal-amrit.mp4';
import fasalAmritImg from '../../assets/agriculture/benefits/fasal-amrit.jpg';
import './benefits.css';

gsap.registerPlugin(ScrollTrigger);

const benefitsList = [
  {
    title: '100% Biodegradable & Organic',
    desc: 'Purely made out of agricultural waste, Fasal Amrit is a completely biodegradable soil conditioner that leaves no residue. ',
  },
  {
    title: 'Retains 100 times its weight',
    desc: 'Fasal Amrit absorbs and locks water up to 100 times its weight and releases moisture as per the soil’s needs.',
  },
  {
    title: 'Adds micro-nutrients to the soil',
    desc: 'It dissolves into the soil and leaves essential micro-nutrients that fertilize and nourish the soil organically.',
  },
  {
    title: 'Improves soil health',
    desc: 'By enriching the soil and increasing its retention capacity, Fasal Amrit reduces nutrient leaching and encourages ecosystem growth.',
  },
  {
    title: 'Enhances yield quality and quantity ',
    desc: 'Fasal Amrit supports plant growth by ensuring continuous moisture and nourishment, which results in better yield.',
  },
];

// Sticky
export const Benefits = () => {
  const containerRef = useRef();
  const videoRef = useRef();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isLargeDevice, setisLargeDevice] = useState(!(window.innerWidth < 768));

  useEffect(() => {
    if (!isLargeDevice || !isVideoLoaded) {
      return;
    }

    const ctx = gsap.context(() => {
      const anim = gsap.from(containerRef.current, {});
      ScrollTrigger.create({
        animation: anim,
        trigger: containerRef.current,
        start: 'top top',
        end: 'bottom -500%',
        scrub: 1,
        pin: true,
        onUpdate: (e) => {
          if (videoRef?.current) {
            videoRef.current.currentTime = (videoRef?.current?.duration || 0) * e.progress - 0.03;
          }
        },
      });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top top',
          end: 'bottom -600%',
          scrub: 1,
        },
      });

      // GSAP Timelines
      {
        tl.to('.text1', {
          top: '50%',
        })
          .to('.text1', {
            top: '50%',
          })
          .to(
            '.text1',
            {
              top: window.innerWidth < 1024 ? '40%' : '35%',
              opacity: 0,
            },
            '<100%',
          )
          .to('.text2', {
            top: '50%',
          })
          .to('.text2', {
            top: '50%',
          })
          .to(
            '.text2',
            {
              top: window.innerWidth < 1024 ? '40%' : '35%',
              opacity: 0,
            },
            '<100%',
          )
          .to('.text3', {
            top: '50%',
          })
          .to('.text3', {
            top: '50%',
          })
          .to(
            '.text3',
            {
              top: window.innerWidth < 1024 ? '40%' : '35%',
              opacity: 0,
            },
            '<100%',
          )
          .to('.text4', {
            top: '50%',
          })
          .to('.text4', {
            top: '50%',
          })
          .to(
            '.text4',
            {
              top: window.innerWidth < 1024 ? '40%' : '35%',
              opacity: 0,
            },
            '<100%',
          )
          .to('.text5', {
            top: '50%',
          })
          .to('.text5', {
            top: '50%',
          })
          .to(
            '.text5',
            {
              top: window.innerWidth < 1024 ? '40%' : '35%',
              opacity: 0,
            },
            '<100%',
          );
      }
    });

    return () => {
      ctx.revert();
    };
  }, [isLargeDevice, isVideoLoaded]);

  function handleResize() {
    window.innerWidth < 768 ? setisLargeDevice(false) : setisLargeDevice(true);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <section
        ref={containerRef}
        className='growers-benefits overflow-hidden blade-pt blade-pb px-4 bg-brownLight min-h-screen md:py-0 md:min-h-[550px] md:h-screen md:flex md:flex-col md:justify-center md:items-center'
      >
        {/* Heading */}
        <div className='grid gap-3 animate-box-fade-in'>
          <p
            id='text'
            className='uppercase font-plainNormal text-green text-center tracking-wider text-[14px] md:text-lg xl:text-xl md:-mt-2'
          >
            Benefits
          </p>
          <h2
            id='text'
            className='text-greenDark font-plainBold text-center leading-tight tracking-tight'
          >
            What Fasal Amrit offers?
          </h2>
          <p id='text' className='text-black font-plainNormal md:text-lg text-sm text-center'>
            Your crops need better nourishment & growth!
          </p>
        </div>

        {/* Video part */}
        <div
          className='w-fit mx-auto flex flex-col items-center blade-mt-sm
          md:grid md:grid-cols-12 md:mt-16
          2xl:mt-28 min-[1800px]:mt-38
        '
        >
          {/* For phone */}
          <article className='md:hidden'>
            <img src={fasalAmritImg} alt='Fasal Amrit benefits' className='blade-mb-sm sm:px-12' />
            <ul>
              {benefitsList.map((benefit, index) => {
                return (
                  <li key={index} className='mt-8 first:mt-0'>
                    <div className='font-plainSemibold text-greenDark mx-auto border border-green bg-greenLight rounded-full grid place-content-center p-3 w-12 h-12'>
                      {index + 1}
                    </div>
                    <p className='font-plainSemibold text-center md:text-left text-greenDark text-xl mt-2.5'>
                      {benefit.title}
                    </p>
                    <p className='font-plainNormal opacity-60 text-center md:text-left mt-2 text-sm md:text-base sm:max-w-[96%]'>
                      {benefit.desc}
                    </p>
                  </li>
                );
              })}
            </ul>
          </article>

          {/* For desktop */}
          <div
            className='w-full hidden mix-blend-darken
              md:block md:col-span-7 md:scale110 md:pl-6
              lg:col-start-2 lg:pl-0 lg:pr-12
              xl:col-start-3 xl:col-end-9 xl:w-full
              min-[1400px]:w-[90%]
              2xl:col-start-4 2xl:col-end-8 2xl:w-full 2xl:scale-110
            '
          >
            <video
              ref={videoRef}
              // poster={fasalAmritImg}
              // controls
              preload='auto'
              onLoadedData={() => {
                setIsVideoLoaded(true);
              }}
              muted
              playsInline
            >
              <source src={fasalAmritVid} type='video/mp4' />
              <p>Your browser doesn't support HTML video.</p>
            </video>
          </div>

          {benefitsList.map((benefit, index) => {
            return <Pointer key={index} benefit={benefit} extraClasses={'text' + (index + 1)} />;
          })}
        </div>
      </section>
    </div>
  );
};

function Pointer({ benefit, extraClasses }) {
  return (
    <div
      className={`w-fit max-w-[18rem] hidden absolute right-[4%] top-full md:block 
        lg:max-w-xs lg:right-[7%] 
        xl:right-[12%] 
        min-[1400px]:right-[16%] min-[1400px]:max-w-[22rem]
        min-[1600px]:right-[20%] min-[1800px]:right-[23%]
    ${extraClasses}
  `}
    >
      <p className={`font-plainBold text-center md:text-left text-greenDark text-xl mt-2.5 `}>
        {benefit.title}
      </p>
      <p
        className={`font-plainNormal opacity-60 text-center md:text-left mt-2 text-sm md:text-base sm:max-w-[96%] `}
      >
        {benefit.desc}
      </p>
    </div>
  );
}
