import React from 'react';

export default function SectionLink({ title, className, darkTheme, sectionId }) {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      {!darkTheme ? (
        <button
          aria-label={title}
          onClick={() => {
            scrollToSection(sectionId);
          }}
          className={`${className} outline-green outline-offset-4 text-greenDark border-greenDark border bg-transparent hover:bg-greenDark hover:text-white transition rounded-full `}
        >
          {title}
        </button>
      ) : (
        <button
          aria-label={title}
          onClick={() => {
            scrollToSection(sectionId);
          }}
          className={`${className} outline-green outline-offset-4 text-white border-yellow border hover:bg-yellow hover:text-greenDark transition rounded-full `}
        >
          {title}
        </button>
      )}
    </>
  );
}
