import React from 'react';
import innerLogo from '../../../assets/globals/inner circle with logo.svg';

export default function Banner({ title, subtitle, logotext, className }) {
  return (
    <section className='bg-greenDark'>
      <section
        className={`min-h-[520px] md:min-h-[550px] md:h-screen !bg-cover bg-bottom bg-no-repeat overflow-hidden relative flex ${className}`}
      >
        <div className='absolute banner-gradient-top top-0 left-0 right-0 bottom-0 z-30 h-full w-full bg-opacity-50'></div>

        <div className='flex justify-end max-w-[1560px] w-full px-6 sm:px-12 md:w-11/12 lg:w-10/12 mx-auto gap-3 md:gap-4 flex-col min-h-full pt-5 pb-12 md:pb-20 lg:pb-28 2xl:pb-36'>
          <h4 className='bg-clip-text text-white font-plainSemibold text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl'>
            {title}
          </h4>
          <span className='font-plainThin text-xl xl:text-[1.5rem] 2xl:text-3xl max-w-xs lg:max-w-md 2xl:max-w-xl text-white '>
            {subtitle}
          </span>
        </div>
        <div className='md:block hidden h-40 xl:h-[180px] absolute top-auto bottom-8 left-auto right-[10%] lg:right-[11%]'>
          <img
            className='w-full h-full animate-spin-slow object-contain object-center'
            src={logotext}
            alt='EF Polymer for business'
          />
          <img
            className='h-28 xl:h-[120px] absolute left-0 mx-auto my-auto right-0 bottom-0 top-0 w-32 xl:w-36 2xl:w-44 object-contain object-center'
            src={innerLogo}
            alt='EF Polymer logo'
          />
        </div>
      </section>
    </section>
  );
}
