import React from 'react';
import { ContentTile } from '../../../modules/atoms';

import './style.css';
import CircularSlider from './circularSlider';
import CircularSliderMobile from './circularSliderMobile';

const Creation = () => {
  return (
    <section className='container blade-pb-sm lg:blade-pb xl:blade-pt-sm'>
      <ContentTile
        label='THE CREATION'
        className='max-w-2xl mx-auto'
        animTrigger='animate-creation'
        center
        title='Presenting the world’s first organic soil moisturizer'
        p2="Rejuvenating the soil and roots is the best way to strengthen nature’s restoration. Building on that philosophy, we worked on a scientific approach to create one of the world's first and only eco-friendly superabsorbent polymer."
      />
      <div className='flex items-center justify-center  mt-6 md:mt-8 lg:mt-10'>
        <div className='bg-[#FEC601] py-4 px-6 rounded-tl-2xl rounded-tr-md rounded-bl-md rounded-br-2xl'>
          <h6 className='text-[#224908] text-center font-plainNormal text-base sm:text-lg lg:text-xl 2xl:text-2xl mb-1'>
            Peel the orange to unwrap what this natural soil moisturizer has to offer!
          </h6>
        </div>
      </div>

      <div className='hidden lg:block'>
        <CircularSlider />
      </div>

      <div className='block lg:hidden'>
        <CircularSliderMobile />
      </div>
    </section>
  );
};

export default Creation;
