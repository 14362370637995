import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import MixtureAdded from '../../../assets/homepage/creations/mixture-added.webp';
import MixtureDissolve from '../../../assets/homepage/creations/mixture-dissolves.webp';
import OrangePeels from '../../../assets/homepage/creations/orangepeel.webp';
import CombiningWater from '../../../assets/homepage/creations/combining-water.webp';
import PolymerTesting from '../../../assets/homepage/creations/polymer-testing.webp';

export default function CircularSlider() {
  const [currStateInd, setCurrStateInd] = useState(0);

  const handleClick = (val) => {
    setCurrStateInd(val);
  };

  const stopCarousel = () => {
    return clearInterval();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (currStateInd >= 4) {
        stopCarousel();
        setCurrStateInd(data.length);
      } else {
        setCurrStateInd(currStateInd + 1);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [currStateInd]);

  const data = [
    {
      title: 'Fruit peels',
      description: '100% Biodegradable polymer created out of fruit peels',
      src: OrangePeels,
    },
    {
      title: 'The polymer being tested in the lab',
      description: 'Certified and tested by renowned institutions',
      src: PolymerTesting,
    },
    {
      title: 'Enhancing water absorption',
      description: 'Retains up to 50 times its weight for agricultural use*',
      src: CombiningWater,
    },
    {
      title: 'The mixture is added to the soil',
      description: 'Nourishes the soil with essential micro-nutrients',
      src: MixtureAdded,
    },
    {
      title: 'The mixture dissolves into the ground',
      description: 'Increases the soil’s water retention capacity ',
      src: MixtureDissolve,
    },
  ];

  return (
    <div className='h-fit relative md:mt-40 mt-12 flex justify-center lg:pb-11 items-center animate-box-fade-in'>
      <div className='h-[28.8rem]  w-[28.8rem] grid place-content-center'>
        <div className='circle  h-[21.6rem] w-[21.6rem] relative rounded-full bg-greenDark group z-[17] overflow-hidden grid place-content-center'>
          {data.map((item, ind) => {
            return (
              <div
                key={ind}
                className='h-full group absolute top-0 w-full p-20 group-hover:scale-110 transition-all duration-1000'
              >
                {currStateInd >= 5 ? (
                  <img
                    className={`${
                      currStateInd >= 5
                        ? 'opacity-1 scale-[1.6] blur-0'
                        : 'opacity-0 scale-75 blur-md'
                    } transition-all duration-1000 `}
                    src={data[4].src}
                    alt={data[4].title}
                  />
                ) : (
                  <img
                    className={`${
                      currStateInd === ind
                        ? 'opacity-1 scale-[1.6] blur-0'
                        : 'opacity-0 scale-75 blur-md'
                    }  transition-all duration-500`}
                    src={data[ind].src}
                    alt={item.title}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div className='dots-wrapper h-[28.8rem] w-[28.8rem] border-2 absolute top-0 left-0 right-0 m-auto rounded-full border-greenDark border-opacity-70'>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                tabIndex={0}
                className={`dot grid place-content-center rounded-2xl outline-green`}
                onClick={() => handleClick(index)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleClick(index);
                  }
                }}
              >
                <div
                  className={`content-circle z-[9999] transition-all duration-500 h-[1.3rem] w-[1.3rem] rounded-full grid place-content-center bg-greenDark bg-opacity-25 text-white ${
                    currStateInd === index || index <= currStateInd ? 'activate' : ''
                  }`}
                ></div>
                <div
                  className={`${
                    currStateInd === index || index <= currStateInd ? '!opacity-100' : ''
                  } content absolute w-[240px] xl:w-[280px] 2xl:w-[228px] opacity-30 2xl:px-1 xl:px-10 py-4 px-5 text-center text-greenDark transition-all duration-1000 translate-y-[-100%]`}
                >
                  <p className='text-greenDark text-md font-plainBold leading-tight font-plain text-xl'>
                    {item.title}
                  </p>
                  <p className='text-sm mt-1 font-plainLight'>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
