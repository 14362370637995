import ContentTile from '../../modules/atoms/contentTile';
import Farmer from '../../assets/agriculture/impact/farmer.webp';
import waterDrops from '../../assets/agriculture/impact/waterDrops.webp';
import fertilizerBag from '../../assets/agriculture/impact/fertilizerBag.webp';
import pigiBank from '../../assets/agriculture/impact/pigiBank.webp';
import yields from '../../assets/agriculture/impact/yield.webp';
import growUp from '../../assets/agriculture/impact/growUp.webp';
import dashedCircle from '../../assets/agriculture/impact/dash_circles.svg';
import './index.css';
import React from 'react';

const data = [
  {
    id: 1,
    icon: waterDrops,
    percentage: 40,
    title: "Less usage <br class='hidden sm:block'/> of water",
  },
  {
    id: 2,
    icon: fertilizerBag,
    percentage: 20,
    title: "Reduced usage <br class='hidden sm:block' /> of fertilizers ",
  },
  {
    id: 3,
    icon: pigiBank,
    percentage: '15',
    title: "Average cost <br class='hidden sm:block' /> reduced ",
  },
  {
    id: 4,
    icon: yields,
    percentage: '10',
    title: "Minimum increase <br class='hidden sm:block' /> in yield ",
  },
  {
    id: 5,
    icon: growUp,
    percentage: '20',
    title: "Average increase <br class='hidden sm:block' /> in profits",
  },
];

export const Impact = () => {
  return (
    <div className='blade-pt px-4 blade-pb flex flex-col items-center justify-center bg-brownLight  relative '>
      <div className='absolute bottom-4 md:bottom-5 right-5'>
        <p className='text-xs opacity-60 font-plainThin'> *Terms & Conditions Apply</p>
      </div>
      <ContentTile
        title='Cultivate smartly'
        center
        className='mx-auto max-w-2xl'
        p2='Growing a plant or a crop can be challenging, especially in arid regions. But with Fasal Amrit, you can retain water, reduce irrigation rounds and maximize the nourishment for your crops effortlessly.'
        label='The Impact'
      />
      <div className='relative mt-6 sm:mt-8 lg:mt-12 w-[250px] h-[250px] sm:w-[280px] sm:h-[280px] md:w-[350px] md:h-[350px] lg:w-[420px] lg:h-[420px] 2xl:w-[550px] 2xl:h-[550px] flex p-6 md:p-8 lg:p-10 justify-center items-center'>
        <div
          className='absolute animate-spin-slower inset-0 w-full h-full bg-contain'
          style={{
            background: `url(${dashedCircle})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
          }}
        />
        <img
          className='z-10 w-full h-[115%] -translate-y-5 object-cover'
          src={Farmer}
          alt='Farmer showing the front of Fasal-Amrit product'
        />
      </div>
      <div className='mt-8 lg:mt-12 flex px-12 flex-wrap flex-col sm:flex-row items-center justify-center gap-y-10 gap-x-6 md:gap-x-12 lg:gap-x-8 xl:gap-x-12'>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <div className='flex flex-col  items-center'>
              <div className='mx-auto h-14 sm:h-20 lg:h-16 xl:h-20 w-14 sm:w-20 lg:w-16 xl:w-20 border border-green bg-greenLight rounded-full'>
                <img src={item.icon} className='w-fit h-full object-contain p-4' alt={item.title} />
              </div>

              <p className='text-greenDark text-center mt-2 font-bold text-2xl font-plainNormal'>{`${item.percentage}%*`}</p>
              <p
                className='text-lg w-[200px] md:w-auto leading-6 text-center font-plainLight mt-1 opacity-70'
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </div>
            {item.id !== data.length && (
              <div className='impact-divider sm:hidden lg:block w-[80%] h-[2px] sm:w-[2px] sm:h-[100px]' />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
