import { ContentTile } from '../../modules/atoms';
import Baif from '../../assets/technology/collaboration/baif_logo.webp';
import Climate from '../../assets/technology/collaboration/climate_logo.webp';
import IIT from '../../assets/technology/collaboration/iit_logo.webp';
import Incubation from '../../assets/technology/collaboration/incubation_logo.webp';
import Royal from '../../assets/technology/collaboration/royal_logo.webp';
import Sewa from '../../assets/technology/collaboration/sewa_logo.webp';
import EFPLogo from '../../assets/globals/efp_logo.svg';
import OuterCircle from '../../assets/technology/collaboration/outer-circle.svg';
import InnerCircle from '../../assets/technology/collaboration/inner-circle.svg';

const leftLogos = [
  {
    id: 1,
    url: Baif,
    link: 'https://baif.org.in/',
    alt: 'BIAF logo',
  },
  {
    id: 2,
    url: IIT,
    link: 'https://www.iitk.ac.in/',
    alt: 'IIT Kanpur logo',
  },
  {
    id: 3,
    url: Incubation,
    link: 'https://siicincubator.com/incubation/incubation_at_siic.php',
    alt: 'Incubation and Innovation logo',
  },
];

const rightLogos = [
  {
    id: 1,
    url: Royal,
    link: 'https://raeng.org.uk/',
    alt: 'Royal academy of engineering',
  },
  {
    id: 2,
    url: Sewa,
    link: 'https://www.sevamandir.org/',
    alt: 'Seva Sadhna Kranti logo',
  },
  {
    id: 3,
    url: Climate,
    link: 'https://climatelaunchpad.org/',
    alt: 'Climate launchpad logo',
  },
];

export const Collaboration = () => {
  return (
    <div className='blade-pt pb-12 lg:blade-pb bg-brownMedium'>
      <div className='container'>
        <ContentTile
          label='Collaborations'
          title='Growing with believers'
          className='max-w-xl mx-auto'
          center
          p2='With groundbreaking collaborations like these, achieving a greener and smarter world is easier than you think!'
        />

        <div className='flex flex-row items-center justify-center mt-6 md:mt-12 lg:mt-20 overflow-hidden'>
          <div className='hidden lg:flex flex-col gap-y-4 z-10 lg:translate-x-[15%] xl:translate-x-[20%] 2xl:translate-x-[36%]'>
            {leftLogos.map((logo) => (
              <a
                href={logo.link}
                target='_blank'
                aria-label={logo.alt}
                key={logo.id}
                rel='noreferrer'
                className='bg-white group rounded-xl outline-green w-[150px] sm:w-[180px] lg:w-[240px] h-[80px] sm:h-[100px] flex items-center justify-center'
              >
                <img
                  src={logo.url}
                  alt={logo.alt}
                  className='group-hover:scale-105 transition-all duration-500'
                />
              </a>
            ))}
          </div>

          <div
            className='w-[180px] h-[180px] sm:w-[300px] sm:h-[300px] lg:w-[500px] lg:h-[500px] relative flex flex-col 
        items-center justify-center'
          >
            <div
              className='absolute animate-spin-slower-reverse inset-0 bg-contain'
              style={{
                background: `url(${OuterCircle})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
            <div
              className='absolute animate-spin-slower inset-0 top-0 my-auto left-0 mx-auto
          w-[120px] sm:w-[180px] lg:w-[340px] xl:w-[380px] h-[120px] sm:h-[180px] lg:h-[340px] xl:h-[380px] bg-contain'
              style={{
                background: `url(${InnerCircle})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <div className=''>
              <img
                className='w-12 sm:w-20 md:w-28 lg:w-36 xl:w-40'
                src={EFPLogo}
                alt='EF Polymer logo'
              />
            </div>
          </div>

          <div className='hidden lg:flex flex-col z-10 gap-y-4 lg:-translate-x-[15%] xl:-translate-x-[20%] 2xl:-translate-x-[36%]'>
            {rightLogos.map((logo) => (
              <a
                href={logo.link}
                target='_blank'
                rel='noreferrer'
                aria-label={logo.alt}
                key={logo.id}
                className='bg-white px-4 outline-green group rounded-xl w-[150px] sm:w-[180px] lg:w-[240px] h-[80px] sm:h-[100px] flex items-center justify-center'
              >
                <img
                  src={logo.url}
                  alt={logo.alt}
                  className='group-hover:scale-105 transition-all duration-500'
                />
              </a>
            ))}
          </div>
        </div>

        <div className='flex lg:hidden flex-wrap items-center justify-center mt-6 gap-4'>
          {leftLogos.map((logo) => (
            <a
              href={logo.link}
              target='_blank'
              aria-label={logo.alt}
              key={logo.id}
              rel='noreferrer'
              className='bg-white p-4 rounded-xl w-[150px] sm:w-[180px] h-[80px] sm:h-[100px] flex items-center justify-center'
            >
              <img className='h-full object-contain' src={logo.url} alt={logo.alt} />
            </a>
          ))}

          {rightLogos.map((logo) => (
            <a
              href={logo.link}
              target='_blank'
              rel='noreferrer'
              aria-label={logo.alt}
              key={logo.id}
              className='bg-white p-4 rounded-xl w-[150px] sm:w-[180px] h-[80px] sm:h-[100px] flex items-center justify-center'
            >
              <img className='h-full object-contain' src={logo.url} alt={logo.alt} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
