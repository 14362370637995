import React, { useEffect } from 'react';

import { gsap } from 'gsap';

import Banner from './banner';
import OurStory from './our-story';
import Creation from './creation';
import Products from './products';
import Testimonials from './testimonials';
import Locations from './locations';
import ResourcesNews from './resources-news';
import Collaborations from './collaborations';
import GetinTouch from '../../modules/core/get-in-touch';

export default function HomeScreen() {
  useEffect(() => {
    const allBox = document.querySelectorAll('.animate-box-fade-in');
    allBox.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, y: 40 },
        {
          opacity: 1,
          duration: 1,
          y: 0,
          stagger: 0.1,
          scrollTrigger: {
            trigger: item,
            start: 'top 90%',
            markers: false,
          },
        },
      );
    });

    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <section>
      <Banner />

      <div className='bg-brownLight'>
        <OurStory />
        <Creation />
      </div>

      <div className='bg-[#F1EDE6]'>
        <Products />
      </div>
      <div className='bg-greenDark'>
        <Testimonials />
      </div>
      <div className='bg-[#FDF9F5]'>
        <Collaborations />
      </div>
      <div className='bg-[#F1EDE6]'>
        <Locations />
      </div>
      <div className='bg-brownLight'>
        <ResourcesNews />
      </div>

      <GetinTouch bg='#FDF9F5' />
    </section>
  );
}
