import React from 'react';
import bg from '../../../assets/globals/bg-circular-dashed.svg';
import './style.css';
import productFront from '../../../assets/homepage/fasal-amrit-product/product-front.webp';
import productBack from '../../../assets/homepage/fasal-amrit-product/product-back.webp';

import omj from '../../../assets/homepage/fasal-amrit-product/logos/omj.svg';
import efci from '../../../assets/homepage/fasal-amrit-product/logos/efci.svg';
import ecocert from '../../../assets/homepage/fasal-amrit-product/logos/ecocert.svg';

const logos = [
  {
    logo: ecocert,
    alt: 'ECOCERT Inputs logo',
  },
  {
    logo: omj,
    alt: 'OMJ logo',
  },
  {
    logo: efci,
    alt: 'EFCI',
  },
];

export default function ProductsView() {
  return (
    <div className='animate-box-fade-in'>
      <div className='mt-8 lg:mt-12'>
        <div className='grid place-content-center'>
          <div
            className='p-5 bg-contain '
            style={{
              background: `url(${bg})`,
              backgroundSize: '100%',
            }}
          >
            <div
              className='flip-card w-fit h-fit bg-greenDark rounded-full'
              style={{
                perspective: '1000px',
              }}
            >
              <div
                className='flip-card-inner relative transition-transform duration-700 
                rounded-full 2xl:h-[32rem] 2xl:w-[32rem] lg:h-[25rem] lg:w-[25rem] h-[16rem] w-[16rem] bggreenDark
              '
                style={{
                  transformStyle: 'preserve-3d',
                }}
              >
                <div className='flip-card-front absolute top-0 left-0 w-full h-full rounded-full'>
                  <img
                    className='w-full h-full ld:p-10 p-5 object-contain'
                    src={productFront}
                    alt='Front view of the fasal-amrit product'
                  />
                </div>
                <div className='flip-card-back absolute top-0 left-0 w-full h-full rounded-full'>
                  <img
                    className='w-full h-full ld:p-10 p-5 object-contain'
                    src={productBack}
                    alt='Back view of the fasal-amrit product'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-6 lg:mt-12 flex gap-6 w-fit mx-auto justify-center items-center md:gap-8 xl:gap-10'>
        {logos.map((logo, index) => {
          return <img key={index} src={logo.logo} alt={logo.alt} className='h-auto w-20 lg:w-24' />;
        })}
      </div>
    </div>
  );
}
