import React, { useEffect } from 'react';
import Banner from '../../modules/core/banner';
import Info from './info';
import Career from '../../modules/core/career';
import './contact.css';
import logoTextWrapper from '../../assets/contact/outer circle.svg';
import contact_us from '../../assets/contact/contact_us.webp';
import GetinTouch from '../../modules/core/get-in-touch';

export default function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <section>
      <Banner
        title='Let’s talk!'
        subtitle='Share your thoughts and ideas for a more sustainable future!'
        pageTitle='Contact us'
        logotext={logoTextWrapper}
        className='banner-wrapper'
      />
      <Info />
      <Career
        image={contact_us}
        containerStyle='bg-brownMedium'
        cardStyle='bg-cream contact-career-card'
      />
      <GetinTouch bg='#F1EDE6' head='Got something to say?' subhead='Let’s connect and explore!' />
    </section>
  );
}
