import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React from 'react';

gsap.registerPlugin(ScrollTrigger);

export default function ContentTile({
  label,
  title,
  p1,
  p2,
  p3,
  p4,
  className,
  center,
  themeDark,
}) {
  return (
    <div
      className={`${className}  ${
        center ? 'text-center mx-auto' : ''
      } grid gap-3 animate-box-fade-in`}
    >
      {themeDark ? (
        <>
          <p
            id='text'
            className='text-white uppercase font-plainNormal tracking-wider text-[14px] md:text-lg xl:text-xl'
          >
            {label}
          </p>
          <h2 id='text' className='text-yellow font-plainBold leading-tight'>
            {title}
          </h2>
          <div className={`${center ? '' : 'pr-10'} grid gap-3`}>
            {p1 ? (
              <p
                id='text'
                className='opacity-60 font-plainNormal text-white animate-text-fade-in md:text-lg text-[14px]'
              >
                {p1}
              </p>
            ) : (
              ''
            )}
            {p2 ? (
              <p id='text' className='text-white font-plainNormal md:text-lg text-[14px]'>
                {p2}
              </p>
            ) : (
              ''
            )}

            {p3 ? (
              <p id='text' className='text-white font-plainNormal md:text-lg text-[14px]'>
                {p3}
              </p>
            ) : (
              ''
            )}
            {p4 ? (
              <p
                id='text'
                className='text-yellow md:px-10 font-plainNormal md:text-lg text-[14px]'
                dangerouslySetInnerHTML={{ __html: p4 }}
              />
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <>
          <p
            id='text'
            className='uppercase font-plainNormal text-green tracking-wider text-[14px] md:text-lg xl:text-xl'
          >
            {label}
          </p>
          <h2 id='text' className='text-greenDark font-plainBold leading-tight tracking-tight'>
            {title}
          </h2>
          <div className={`${center ? '' : 'pr-10'} grid gap-3`}>
            {p1 ? (
              <p
                id='text'
                className='font-plainNormal opacity-60 md:text-lg text-[14px] leading-normal'
              >
                {p1}
              </p>
            ) : (
              ''
            )}
            {/* {p2 ? (
              <p id="text" className=" font-plainNormal md:text-lg text-sm ">
                {p2}
              </p>
            ) : (
              ""
            )} */}

            {p2 ? (
              <p
                id='text'
                className='text-black font-plainNormal md:text-lg text-sm'
                dangerouslySetInnerHTML={{ __html: p2 }}
              />
            ) : (
              ''
            )}
            {p3 ? (
              <p id='text' className='text-[#5D5D5D] font-plainNormal md:text-lg text-sm '>
                {p3}
              </p>
            ) : (
              ''
            )}
            {p4 ? (
              <p
                id='text'
                className='text-greenDark font-plainNormal md:text-lg text-sm md:px-10'
                dangerouslySetInnerHTML={{ __html: p4 }}
              />
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  );
}
