import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  HomeScreen,
  AboutScreen,
  Error404Screen,
  ContactScreen,
  Technology,
  Resources,
  Agriculture,
  PrivacyPolicy,
} from '../screens';
import { Header, Footer } from '../modules/core';
import { UIContextProvider } from '../context/uiContext';

const ViewWithNavAndFooter = () => {
  return (
    <>
      <UIContextProvider>
        <Header />
        <Outlet />
        <Footer />
      </UIContextProvider>
    </>
  );
};

const routes = () => {
  return [
    {
      path: '/',
      element: <ViewWithNavAndFooter />,
      children: [
        { path: '', element: <HomeScreen /> },
        { path: 'contact-us', element: <ContactScreen /> },
        { path: 'business', element: <Technology /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },

        { path: 'about-us', element: <AboutScreen /> },
        { path: 'resources', element: <Resources /> },
        { path: 'growers', element: <Agriculture /> },
        {
          path: '*',
          element: <Error404Screen />,
        },
      ],
    },
  ];
};

export default routes;
