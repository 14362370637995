import React from 'react';
import linkedinBtn from '../../../assets/contact/linkedin_button.png';
// import naukriBtn from "../../../assets/contact/naukari_button.png";

import contact_us from '../../../assets/contact/contact_us.webp';

const links = [
  {
    id: 1,
    url: 'https://www.linkedin.com/company/13705389/admin/feed/posts/',
    imageUrl: linkedinBtn,
    alt: 'linkedIn logo',
  },
  // { id: 2, url: "#", imageUrl: naukriBtn, alt: "Naukri logo" },
];

export default function Career({ containerStyle, cardStyle }) {
  return (
    <section className={`px-3 pb-6 lg:pb-24 2xl:pb-32 pt-6 md:pt-0 2xl:pt-20 ${containerStyle}`}>
      <div
        className={`container flex flex-col-reverse lg:flex-row rounded-3xl overflow-hidden ${cardStyle}`}
      >
        <div className='px-4 pt-8 pb-6 md:py-10 lg:py-12 xl:py-16 flex flex-col justify-center items-center lg:w-[55%] xl:w-[55%] 2xl:w-[50%]'>
          <div className='flex flex-col items-center lg:items-start'>
            <p className='uppercase text-center lg:text-left text-green font-plainNormal tracking-normal md:tracking-wider text-base xl:text-lg'>
              Careers
            </p>
            <h2 className='text-greenDark text-center lg:text-left font-plainBold leading-tight py-4 max-w-sm xl:max-w-[500px] 2xl:max-w-[450px]'>
              Wish to join the cause and create an impact? 
            </h2>
            <span className='w-fit block'>
              <p className='text-sm text-center lg:text-left md:text-base xl:text-xl font-plainNormal'>
                Explore opportunities on LinkedIn
              </p>
            </span>

            <div className='pt-8 flex gap-2 justify-center lg:justify-start md:gap-5'>
              {links.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  rel='noreferrer'
                  target='_blank'
                  className='grid outline-green outline-offset-4 place-content-center place-items-center mx-1 rounded-md'
                >
                  <img className='h-10 md:h-12 xl:h-auto' src={link.imageUrl} alt={link.alt} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className='hidden lg:block lg:w-[45%] xl:w-[45%] 2xl:w-[50%]'>
          <img
            className='w-full h-full object-cover object-center'
            src={contact_us}
            alt='Three seedlings signifying growth and contribution for a good cause '
          />
        </div>
      </div>
    </section>
  );
}
