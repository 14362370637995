import { useEffect } from 'react';
import { About } from './about';
import { Collaboration } from './collaboration';
import { IntroducePolymer } from './introducePolymer';
import { Possibilities } from './possibilities';
import GetinTouch from '../../modules/core/get-in-touch';
import Banner from '../../modules/core/banner';
import bannerLogo from '../../assets/technology/banner_logo.svg';

const Technology = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Banner
        title='Grow Sustainably'
        pageTitle='technology'
        subtitle='Harness the power of Super Absorbent Polymer for your business and the earth.'
        logotext={bannerLogo}
        className='tech-banner-wrapper'
      />
      <About />
      <IntroducePolymer />
      <Possibilities />
      <Collaboration />

      <GetinTouch bg='#F1EDE6' head='Together, Let’s create a difference!' />
    </>
  );
};

export default Technology;
