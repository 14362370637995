import React, { useEffect, useState } from 'react';
import vdo from '../../../assets/homepage/video_homepage.mp4';
import poster from '../../../assets/homepage/poster.webp';
import { SwitchTransition, Transition } from 'react-transition-group';
import { gsap } from 'gsap';

export default function Banner() {
  const [activeTextInd, setActiveTextInd] = useState(0);

  const list = [
    { text: 'Sustainable Agriculture' },
    { text: 'Organic Farming' },
    { text: 'Water Conservation' },
    { text: 'Agri-waste Management' },
    { text: 'Soil Health' },
    { text: 'Environmental Measures ' },
  ];

  const h = window.innerHeight;
  const handleScroll = () => {
    window.scrollTo({
      top: h - 100,
      left: 100,
      behavior: 'smooth',
    });
  };

  const exitAnim = (node) => {
    gsap.fromTo(
      node,
      {
        y: 0,
        opacity: 1,
      },
      {
        duration: 0.5,
        y: -10,
        opacity: 0,
        delay: 1.4,
      },
    );
  };

  const enter = (node) => {
    gsap.fromTo(
      node,
      {
        y: 10,
        opacity: 0,
      },
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
      },
    );
  };

  const stopCarousel = () => {
    return clearInterval();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeTextInd >= 5) {
        stopCarousel();
        setActiveTextInd(0);
      } else {
        setActiveTextInd(activeTextInd + 1);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [activeTextInd]);

  return (
    <section className='banner_wrapper relative md:h-[110vh] h-screen md:min-h-[600px] min-h-[500px] '>
      <div className='absolute banner-gradient top-0 left-0 right-0 bottom-0 z-30 h-full w-full bg-opacity-50'></div>

      <div className='absolute container top-0 bottom-0 left-0 right-0 md:h-[100vh] grid md:place-content-center place-content-evenly z-40'>
        <div className=' md:pb-32 pb-20 '>
          <h3 className='text-gray font-plainBold text-center leading-tight animate-fade-up'>
            Reimagining
          </h3>
          <div className='overflow-hidden pb-5 pt-2'>
            <SwitchTransition>
              <Transition
                appear={true}
                timeout={1400}
                in={true}
                key={activeTextInd}
                onExit={exitAnim}
                onEnter={enter}
                mountOnEnter
                unmountOnExit
              >
                <h1 className='text-green font-plainBold text-center leading-tight animate-fade-up 2xl:mt-2'>
                  {list[activeTextInd].text}
                </h1>
              </Transition>
            </SwitchTransition>
          </div>
        </div>

        <div className='grid place-content-center gap-3 animate-fade-down w-fit mx-auto'>
          <button
            aria-label='Explore more'
            className='border-2 group border-white rounded-full w-14 h-14 hover:bg-white outline-green outline-offset-4 transition-all mx-auto grid place-content-center'
            onClick={() => handleScroll()}
          >
            <svg
              className=' group-hover:stroke-greenDark stroke-white cursor-pointer transition-all'
              width='46'
              height='46'
              viewBox='0 0 46 46'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M11 19C14.6923 19.6471 22.3231 22.7529 23.3077 30C23.5128 26.7647 26.1385 20.0353 35 19'
                strokeWidth='3'
              />
            </svg>
          </button>
          <p className='text-white font-plainNormal tracking-wide  text-center'>Explore</p>
        </div>
      </div>

      <div className='absolute top-0 left-0 right-0 w-full overflow-hidden md:h-[110vh] h-screen md:min-h-[600px] min-h-[500px]  z-20'>
        <video
          className='h-[120%] w-full object-cover'
          src={vdo}
          poster={poster}
          loop
          autoPlay
          muted
          playsInline
        />
      </div>
    </section>
  );
}
