import React from 'react';
import superabsorbent from '../../../assets/homepage/fasal-amrit-product/icons/superabsorbent.svg';
import biodegradable from '../../../assets/homepage/fasal-amrit-product/icons/biodegradable.svg';
import growmore from '../../../assets/homepage/fasal-amrit-product/icons/grow-more.svg';
import nourishmentprovider from '../../../assets/homepage/fasal-amrit-product/icons/nourishmentprovider.svg';
import retention from '../../../assets/homepage/fasal-amrit-product/icons/retention.svg';
import savemore from '../../../assets/homepage/fasal-amrit-product/icons/save-more.svg';
import soilconditioner from '../../../assets/homepage/fasal-amrit-product/icons/soilconditioner.svg';
import supplement from '../../../assets/homepage/fasal-amrit-product/icons/supplement.svg';

const data = [
  {
    title: 'Soil conditioner',
    des: 'Acts as a source of moisture and keeps the soil moist',
    img: superabsorbent,
    imgAlt: 'icon for superabsorbent soil moisturizer',
    details:
      'When hydrogel is applied to soil in the field, it spreads out the pores or vacant spaces in the soil with holding the moisture and act as a super absorbent hydrogel. ',
  },
  {
    title: 'Organic',
    des: 'Rejuvenates the soil organically',
    img: soilconditioner,
    imgAlt: 'icon for Soil Conditioner',
    details:
      'When applying Fasal Amrit to soil, it contains organic substances that increase the organic carbon in the soil by improving its fertility. After 6 months of application, it decomposes in the soil, improving earthworm growth and soil health.',
  },
  {
    title: 'Nourishment provider',
    des: 'Supplements essential micronutrients',
    img: nourishmentprovider,
    imgAlt: 'icon for Nourishment provider',
    details:
      'Fasal Amrit contains some plant-essential micronutrients that reliably help the growth and yield of crops. When Fasal Amrit is applied to soil, it holds moisture and supplies the nutrients with moisture. It translocated nutrients with moisture from the root to the economic part of the plant, as well as affecting the metabolic process of the crop.',
  },
  {
    title: 'Enhances fertilizers',
    des: 'Enhances the application of fertilizers',
    img: supplement,
    imgAlt: 'icon for Enhances fertilizers',
    details:
      'Fasal Amrit contains some plants essential nutrients as well as saving fertilizer by eliminating leaching losses and improving FUE (fertilizer use efficiency) of the plant.',
  },
  {
    title: 'Improves retention',
    des: 'Minimizes fertilizer leaching when watering',
    img: retention,
    imgAlt: 'icon for improves retention',
    details:
      'Application of Fasal Amrit in the soil spreads to the pores or vacant spaces in the soil; due to this, it holds the soil moisture and contains nutrients. It eliminates leaching as well as water losses, and it provides the moisture with nutrients through the process of osmosis.',
  },
  {
    title: 'Bio-degradable polymer',
    des: 'Bio-degrades and generates nothing but nutrients',
    img: biodegradable,
    imgAlt: 'icon for bio-degradable polymers',
    details:
      'After six months of application of Fasal Amit, it decomposes in the soil and enhances its fertility. This is because it is made from bio-waste and is completely eco-friendly.',
  },
  {
    title: 'Save more',
    des: 'Saves input cost, labor, and water',
    img: savemore,
    imgAlt: 'icon for save more',
    details:
      'Application of Fasal Amrit in the soil reduces the irrigation requirement and saves the fertilizer requirement of the crop. This is due to the fact that Fasal Amrit holds the soil moisture through its working mechanism as well as contains the plant essential nutrients, which support crop growth and ultimately reduce the cost of production.',
  },
  {
    title: 'Grow more organically',
    des: 'Boosts yield, crop quality, and growth',
    img: growmore,
    imgAlt: 'icon for Grow more',
    details:
      'Fasal Amrit is an organic superabsorbent polymer made from bio-waste, without harming the soil properties and improves the fertility of the soil. The availability of vital nutrients increases the growth and yield of the crop.',
  },
];

export default function Cards() {
  const ProductsCard = ({ title, des, imgSrc, imgAlt, details }) => {
    return (
      <div className='!bg-white rounded-lg hover:shadow-md transition-all h-full w-full duration-700 leaves'>
        <div
          className='flip-card w-full h-full'
          style={{
            perspective: '1000px',
          }}
        >
          <div
            style={{
              transformStyle: 'preserve-3d',
            }}
            className='flip-card-inner relative transition-transform duration-700 h-[17rem] md:h-[18rem] lg:h-[20rem] 2xl:h-[23rem]'
          >
            <div className='flip-card-front absolute top-1/2 left-0 w-full h-full bg-transparent text-center px-3 2xl:px-6'>
              <div className='flex flex-col gap-2 -translate-y-1/2'>
                {imgSrc ? (
                  <div className=' rounded-full border border-green bg-greenLight text-center overflow-hidden h-28 w-28 p-5 mx-auto'>
                    <img className='h-full object-contain mx-auto' src={imgSrc} alt={imgAlt} />
                  </div>
                ) : (
                  ''
                )}
                <h4 className='text-[#224908] font-plainSemibold mt-4'>{title}</h4>
                <p className=' font-plainLight  w-11/12  mx-auto text-[#5D5D5D]'>{des}</p>
              </div>
            </div>
            <div className='flip-card-back absolute top-1/2 left-0 w-full h-full bg-transparent px-6 text-center '>
              <p className='-translate-y-1/2 max-w-xs mx-auto'>{details}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Old card
  // const ProductsCard = ({ title, des, imgSrc, imgAlt, details }) => {
  //   return (
  //     <div className='bg-white rounded-lg hover:shadow-md transition-all h-full w-full duration-700 '>
  //       <div className='text-center leaves px-3 2xl:px-6 2xl:py-16 py-10 h-full w-full'>
  //         <div className='flex flex-col gap-2 '>
  //           {imgSrc ? (
  //             <div className=' rounded-full border border-green bg-greenLight text-center overflow-hidden h-28 w-28 p-5 mx-auto'>
  //               <img className='h-full object-contain mx-auto' src={imgSrc} alt={imgAlt} />
  //             </div>
  //           ) : (
  //             ''
  //           )}
  //           <h4 className='text-[#224908] font-plainSemibold mt-4'>{title}</h4>
  //           <p className=' font-plainLight  w-11/12  mx-auto text-[#5D5D5D]'>{des}</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className='mx-auto max-w-[1536px] grid lg:grid-cols-3 md:grid-cols-2 grid-col-1 gap-6 min-[1600px]:grid-cols-4'>
      {data.map((item, i) => {
        return (
          <div key={i} className='flex'>
            <ProductsCard
              title={item.title}
              des={item.des}
              imgSrc={item.img}
              imgAlt={item.imgAlt}
              details={item.details}
              link='/'
            />
          </div>
        );
      })}
    </div>
  );
}
